import React from "react";

const Footer: React.FC = () => {
    return (
        <div className="flex flex-col items-center justify-center p-6 bg-gray-800 text-white">
            <div className="mb-4">
                <img
                    src="/assets/images/logo.png" // Substitua pelo caminho da sua logo
                    alt="Logo"
                    className="w-32 h-auto"
                />
            </div>
            <p className="text-lg font-semibold mb-2">
                Criado por Levi
            </p>
            <p className="text-sm mb-4">
                Para mais informações ou suporte, entre em contato com: <a href="mailto:contatocharinfo@gmail.com" className="text-blue-400 hover:underline">contatocharinfo@gmail.com</a>
            </p>
            <a
                href="mailto:contatocharinfo@gmail.com"
                className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600"
            >
                Enviar um e-mail
            </a>
        </div>
    );
};

export default Footer;
