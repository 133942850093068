import React, { useState, FormEvent } from "react";

const BetaSignupForm: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [deviceType, setDeviceType] = useState<string>(""); // Novo estado para tipo de dispositivo
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Previne o comportamento padrão de envio do formulário
    setLoading(true); // Define o estado de carregamento
    setError(null); // Reseta o erro

    const form = e.currentTarget; // Referência ao formulário
    const formData = new FormData(form); // Cria um objeto FormData a partir do formulário

    // Adiciona o tipo de dispositivo ao FormData
    formData.set("device_type", deviceType); // Usa `set` para garantir que o campo seja atualizado corretamente

    fetch('https://formspree.io/f/xkgweglw', {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json',
      },
      redirect: 'manual',  // Evita seguir o redirecionamento
    })
      .then((response) => {
        if (response.ok) {
          setSubmitted(true);  // Sucesso no envio
          form.reset(); // Limpa o formulário
          setDeviceType(""); // Reseta o estado do tipo de dispositivo
        } else {
          response.json().then((data) => {
            if (Object.hasOwn(data, 'errors')) {
              setError(data.errors.map((error: any) => error.message).join(", "));
            } else {
              setError("Oops! Houve um problema ao enviar seu formulário.");
            }
          });
        }
      })
      .catch((error) => {
        setError("Oops! Houve um problema ao enviar seu formulário."); // Erro de rede
      })
      .finally(() => {
        setLoading(false); // Remove o estado de carregamento
      });
  };

  return (
    <div className="flex flex-col items-center justify-center p-6 max-w-md mx-auto bg-gray-800 rounded-lg shadow-lg">
      {submitted ? (
        <div className="text-center text-white">
          <p className="text-lg font-semibold mb-4">
            Sua solicitação foi enviada! 🎉
          </p>
          <p>
            Quando sua solicitação for aceita, você receberá um e-mail com o link para download do Char Info pela Play Store.
          </p>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="w-full">
          <div className="mb-4">
            <label htmlFor="email" className="block text-white text-sm font-semibold mb-2">
              E-mail
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-3 bg-gray-700 border border-gray-600 rounded-lg text-white"
              placeholder="Seu e-mail"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="reason" className="block text-white text-sm font-semibold mb-2">
              Por que você deseja participar?
            </label>
            <textarea
              id="reason"
              name="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              required
              rows={4}
              className="w-full p-3 bg-gray-700 border border-gray-600 rounded-lg text-white"
              placeholder="Conte-nos o motivo"
            ></textarea>
          </div>
          <div className="mb-4">
            <label htmlFor="device_type" className="block text-white text-sm font-semibold mb-2">
              Qual é o seu tipo de dispositivo?
            </label>
            <select
              id="device_type"
              name="device_type"
              value={deviceType}
              onChange={(e) => setDeviceType(e.target.value)}
              required
              className="w-full p-3 bg-gray-700 border border-gray-600 rounded-lg text-white"
            >
              <option value="" disabled>Selecione</option>
              <option value="Android">Android</option>
              <option value="iOS">iOS</option>
            </select>
          </div>
          <button
            type="submit"
            className="w-full py-3 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600"
            disabled={loading}
          >
            {loading ? "Enviando..." : "Enviar"}
          </button>
          {error && (
            <p className="text-red-500 mt-4">{error}</p>
          )}
        </form>
      )}
    </div>
  );
};

export default BetaSignupForm;
