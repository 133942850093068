import React from "react";
import BetaSignupForm from "../../components/email";
import Footer from "../../components/footer";

export default function Home() {
    return (
        <div>
            <div className="min-h-screen w-full flex flex-col items-center p-10">
                <div className="flex flex-col md:flex-row w-full max-w-screen-xl mx-auto gap-10 border-b p-8">
                    <div className="flex flex-col flex-grow justify-center">
                        <p className="text-white text-4xl font-semibold mb-4">
                            Participe do beta teste fechado do Char Info 🚀 e seja um dos primeiros a experimentar!
                        </p>
                        <p className="text-vermelho text-3xl font-semibold mb-4">
                            Sobre
                        </p>
                        <p className="text-white text-lg leading-relaxed">
                            Char Info é um aplicativo avançado de pixel que facilita a busca pelos
                            melhores pixels do seu personagem favorito.
                            Com ele, você pode filtrar facilmente sua pesquisa usando os botões
                            de filtros por Agente, Habilidade e Mapa, permitindo que você veja
                            apenas o que realmente deseja, sem precisar procurar em várias plataformas por
                            um único pixel. Dessa forma, você encontrará rapidamente o melhor pixel no meio
                            de uma partida, ajudando você a fazer a jogada perfeita!
                        </p>
                    </div>
                    <div className="flex-none md:mt-0 mt-10">
                        <img
                            src="/assets/images/celular2.png"
                            alt="Imagem de um celular com o aplicativo"
                            className="w-80 h-auto object-cover"
                        />
                    </div>
                </div>
            </div>
            <div className="flex-col justify-center p-10">
                <p className="text-white text-4xl text-center leading-relaxed px-4 md:px-80 pb-5">
                    Preencha os campos abaixo para se inscrever na beta fechada do nosso aplicativo! 📲
                </p>
                <BetaSignupForm />
            </div>
            <Footer />
        </div>
    );
}
