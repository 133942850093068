import React from 'react';
// import logo from './logo.svg';
import Home from './pages/Home';


function App() {
  return (
    <div className="bg-azul2">
      <Home/>
    </div>
  );
}

export default App;
